import { memo, useCallback, useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Routes } from '../../router';
import { AppLanguage, LANGUAGES } from '../../i18n';
import { Text } from '../../components';
import {
  ATTRACTIVE_FEATURES_ID,
  AVAILABLE_CRYPTOS_ID,
  KNOWLEDGE_ID,
  TESTIMONIALS_ID
} from '../../utils';
import { BurgerMenu } from './BurgerMenu/BurgerMenu';
import './Header.scss';

export const Header = memo(
  function Header() {
    const { t, i18n } = useTranslation();
    const [ selectedLang, setSelectedLang ] = useState(AppLanguage.ENGLISH);

    const navItems = [
      { label: t('layout.top-nav.howItWorks'), path: Routes.HOW_IT_WORKS_PAGE },
      { label: t('layout.top-nav.cryptos'), path: Routes.LANDING_PAGE, sectionId: AVAILABLE_CRYPTOS_ID },
      { label: t('layout.top-nav.features'), path: Routes.LANDING_PAGE, sectionId: ATTRACTIVE_FEATURES_ID },
      { label: t('layout.top-nav.testimonials'), path: Routes.LANDING_PAGE, sectionId: TESTIMONIALS_ID },
      { label: t('layout.top-nav.knowledge'), path: Routes.LANDING_PAGE, sectionId: KNOWLEDGE_ID },
    ];

    const onChangeLang = useCallback(() => {
      if (selectedLang === AppLanguage.ENGLISH) {
        setSelectedLang(AppLanguage.SPANISH);
        i18n.changeLanguage(AppLanguage.SPANISH);
      } else {
        setSelectedLang(AppLanguage.ENGLISH);
        i18n.changeLanguage(AppLanguage.ENGLISH);
      }
    }, [ selectedLang, setSelectedLang, i18n ]);

    return (
      <nav className="header">
        <div className="nav-content display-flex space-between align-items-center">
          <div className="app-title second-color">
            <Link to={Routes.LANDING_PAGE}>
              <Text variant="content1" color="secondary" weight={700}>
                {t('layout.top-nav.crypto')}
              </Text>
            </Link>
          </div>

          <div className="menu-items display-flex space-between align-items-center gap-20">
            {navItems.map((item, index) => (
              <Link key={item.label} to={item.path} state={{ sectionId: item.sectionId }}>
                <Text variant="content2" className="as-link">
                  {item.label}
                </Text>
              </Link>
            ))}
          </div>

          <div className="menu-settings display-flex space-between align-items-center gap-20">
            <Text variant="content2">
              User
            </Text>
            <Text variant="content2" onClick={onChangeLang} className="cursor-pointer">
              {LANGUAGES[selectedLang].code.toUpperCase()}
            </Text>
          </div>

          <BurgerMenu
            navItems={navItems}
            user={{
              value: 'User',
              onClick: () => {
              }
            }}
            language={{
              value: LANGUAGES[selectedLang].code.toUpperCase(),
              onClick: onChangeLang
            }}
          />
        </div>
      </nav>
    );
  }
);