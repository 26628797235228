import React, { memo, useEffect, useState } from 'react';
import {
  differenceInSeconds,
  differenceInMinutes,
  differenceInHours,
  differenceInDays
} from 'date-fns';

function MemoComponent({ targetDate }: {  targetDate: Date }) {
  const calculateTimeLeft = () => {
    const now = new Date();

    const days = differenceInDays(targetDate, now);
    const hours = differenceInHours(targetDate, now) % 24;
    const minutes = differenceInMinutes(targetDate, now) % 60;
    const seconds = differenceInSeconds(targetDate, now) % 60;

    return (
      (hours > 9 ? hours : "0" + hours) +
      ":" +
      (minutes > 9 ? minutes : "0" + minutes) +
      ":" +
      (seconds > 9 ? seconds : "0" + seconds)
    );
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(timer);
  }, [targetDate]);

  return <>{timeLeft}</>;
}

export const CountDownTimer = memo(MemoComponent);
