import React, { useContext, useMemo, useState } from 'react';
import { CryptoList, CryptoValue } from '../../constants';
import { Dropdown } from '../../components';
import { AppContext } from '../../context';
import { SelectableOption } from '../../types';

type Props = {
  lightMode?: boolean;
  size?: 'sm' | 'md' | 'lg';
  className?: string;
}

export const CurrencyDropdown = ({ lightMode, size, className }: Props) => {
  const {
    selectedCrypto,
    setSelectedCrypto,
  } = useContext(AppContext);

  const options: SelectableOption[] = useMemo(() => (
    Object.keys(CryptoList).map((key) => ({
      id: key,
      label: CryptoList[key as CryptoValue].symbol as string
    }))
  ), []);
  const [
    selectedOption,
    setSelectedOption
  ] = useState<SelectableOption>(options.find(item => item.id === selectedCrypto) || options[0]);

  const onSelect = (option: SelectableOption) => {
    setSelectedCrypto(option.id as CryptoValue);
    setSelectedOption(option)
  };

  return (
    <Dropdown
      value={selectedOption}
      onChange={onSelect}
      options={options}
      className={className}
      lightMode={lightMode}
      size={size}
    />
  );
};