import { memo, useCallback, useRef } from 'react';
import { Icon } from '../../components';
import './Carousel.scss';

type Props = {
  children?: React.ReactNode;
}

function MemoCarousel({ children }: Props) {
  const carouselInnerRef = useRef<HTMLDivElement>(null);

  const moveLeft = useCallback(() => {
    if (carouselInnerRef.current) {
      carouselInnerRef.current.scrollBy({ left: -370, behavior: 'smooth' });
    }
  }, []);

  const moveRight = useCallback(() => {
    if (carouselInnerRef.current) {
      carouselInnerRef.current.scrollBy({ left: 370, behavior: 'smooth' });
    }
  }, []);

  return (
    <div className="carousel display-flex gap-10 align-items-center space-between">
      <Icon className="icon-chevron-left" size={24} onClick={moveLeft}/>
      <div className="carousel-inner" ref={carouselInnerRef}>
        {children}
      </div>
      <Icon className="icon-chevron-right" size={24} onClick={moveRight}/>
    </div>
  );
}

export const Carousel = memo(MemoCarousel);