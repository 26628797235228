import React, { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Text } from '../../index';
import { CryptoValue, CryptoList } from '../../../constants';
import './CryptoCard.scss';
import { numberAsUSDFormatted } from '../../../utils';
import { Link } from 'react-router-dom';

export type CryptoCardType = {
  logo: string,
  title: CryptoValue,
  description: string,
  price: number,
  url?: string
};

export const CryptoCard = memo(
  function Card({ card, onClick }: { card: CryptoCardType, onClick: () => void }) {
    const { t } = useTranslation();
    const title = useMemo(() => CryptoList[card.title].name, [ card.title ]);

    const formattedPrice = useMemo(() => {
      const centsToDolars = card.price / 100;
      return numberAsUSDFormatted(centsToDolars, 0);
    }, [ card.price ]);

    return (
      <div className="crypto-card carousel-card">
        <img className="display-block" src={card.logo} alt={`${title}-logo`}/>
        <div className="card-content display-flex-column space-between">
          <div>
            <div className="display-flex space-between mt-10 mb-10">
              <Text variant="content1" color="tertiary" align="left" weight={600}>
                {title}
              </Text>

              { card.url && (
                <Link to={card.url} target="_blank">
                  <Text
                    variant="caption1"
                    color="secondary"
                    weight={500}
                    align="left"
                    className="as-link mt-10"
                  >
                    {t("layout.button.more")}
                  </Text>
                </Link>
              )}
            </div>

            <Text variant="caption1" hasOpacity color="tertiary" align="left" maxLines={3}>
              {card.description}
            </Text>
          </div>

          <div className="card-price display-flex space-between align-items-center">
            <Text variant="subtitle1" color="secondary" weight={700}>
              {formattedPrice}
            </Text>
            <Button label={t('layout.button.buyNow')} onClick={onClick} size="sm"/>
          </div>
        </div>
      </div>
    );
  }
);