import { FooterSections } from './FooterSections/FooterSections';
import { FooterSocials } from './FooterSocials/FooterSocials';
import './Footer.scss';

export const Footer = () => {
  return (
    <footer className="footer">
      <FooterSections/>
      <FooterSocials/>
    </footer>
  );
};