import { memo } from 'react';
import "./Icon.scss";

type Props = {
  className: string,
  containerClassName?: string,
  color?: "primary" | "secondary" | "tertiary",
  size?: number,
  onClick?: () => void,
}

export const Icon = memo(
  function MemoIcon({ className, containerClassName, color, size, onClick }: Props) {
    const colorClass = color ? `color-${color}` : 'color-primary';
    const style= {
      fontSize: size ? `${size}px` : undefined
    };

    return (
      <div className={`icon-container ${containerClassName}`}>
        <span
          className={`icon ${className} ${colorClass}`}
          style={style}
          onClick={onClick}
        />
      </div>
    );
  }
);