import { memo } from 'react';
import { Button, Text } from '../../../components';
import { useTranslation } from 'react-i18next';
import verified from "../../../assets/svg-utils/success.svg";

type Props = {
  id: string;
  onProceed: () => void;
}

function MemoComponent({ id, onProceed }: Props) {
  const { t } = useTranslation();
  return (
    <div
      id={id}
      className="kyc-verified kyc-flow-content"
    >
      <img src={verified} className="display-block" alt="verified"/>

      <Text variant="title2" weight={700} color="secondary">
        {`${t('kyc.thirdStep.verified')}!`}
      </Text>

      <Text variant="content1" color="tertiary">
        {t('kyc.thirdStep.successfullyVerifiedYourId')}
      </Text>

      <Button
        label={t('buyPage.proceedButton')}
        onClick={onProceed}
      />
    </div>
  );
}

export const KYCVerified = memo(MemoComponent);