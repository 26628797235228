import { memo, useCallback, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Text, Button, RadioInput } from '../../../components';
import { IdentityMethod } from '../../../constants';
import { AppContext } from '../../../context';
import "./SelectKYCMethod.scss";
import { SelectableOption } from '../../../types';

type Props = {
  id: string;
  onNext: () => void;
}

const MemoComponent = function({ id, onNext }: Props) {
  const { t} = useTranslation();
  const { selectedIdentityMethod, setSelectedIdentityMethod } = useContext(AppContext);

  const methods = [
    { id: IdentityMethod.Passport, label: t("kyc.option.passport")},
    { id: IdentityMethod.DriverLicense, label: t("kyc.option.driverLicense")},
    { id: IdentityMethod.IdentityCard, label: t("kyc.option.identityCard")}
  ];

  const onSelect = useCallback((option: SelectableOption) => {
    setSelectedIdentityMethod(option.id as IdentityMethod);
  }, [setSelectedIdentityMethod]);

  useEffect(() => {
    setSelectedIdentityMethod(undefined);
  }, [setSelectedIdentityMethod]);

  return (
    <div
      id={id}
      className="select-kyc-method kyc-flow-content"
    >
      <Text variant="title2" weight={700} color="secondary">
        {t("kyc.firstStep.weNeedToVerifyYourID")}
      </Text>

      <Text variant="content1" color="tertiary">
        {t("kyc.firstStep.selectTypeOfId")}
      </Text>

      <ul className="methods-list">
        {methods.map((item) => (
          <li key={item.id} className="mb-20">
            <RadioInput
              value={item}
              isSelected={item.id === selectedIdentityMethod}
              onSelect={onSelect}
            />
          </li>
        ))}
      </ul>

      <Button
        label={t("layout.button.next")}
        onClick={onNext}
        isDisabled={!selectedIdentityMethod}
      />
    </div>
  );
}

export const SelectKYCMethod = memo(MemoComponent);