import { Text } from '../../../components';
import { Link } from 'react-router-dom';
import './ArticleCard.scss';
import { useTranslation } from 'react-i18next';

export type ArticleCardType = {
  title: string,
  content: string,
  image: string,
  url: string,
}

export const ArticleCard = ({ article }: { article: ArticleCardType }) => {
  const { t } = useTranslation();

  return (
    <div className="article-card display-flex gap-20">
      <img
        className="article-image display-block"
        src={article.image}
        alt={`${article.title}`}
      />

      <div className="display-flex-column space-between">
        <Text variant="content1" weight={600} align="left" maxLines={2}>
          {article.title}
        </Text>
        <Text variant="caption1" hasOpacity align="left" maxLines={3}>
          {article.content}
        </Text>

        <Link to={article.url} target="_blank">
          <Text
            variant="caption1"
            color="secondary"
            weight={500}
            align="left"
            className="as-link"
          >
            {t("layout.button.learnMore")}
          </Text>
        </Link>
      </div>
    </div>
  );
};