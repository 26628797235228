import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Text } from '../../Text/Text';
import { Routes } from '../../../router';
import {
  ATTRACTIVE_FEATURES_ID,
  AVAILABLE_CRYPTOS_ID,
  KNOWLEDGE_ID,
  TESTIMONIALS_ID
} from '../../../utils';
import { Button, InputField } from '../../../components';
import './FooterSections.scss';

export const FooterSections = () => {
  const { t } = useTranslation();
  const [email, setEmail] = useState('');

  const links = [
    { label: t('layout.footer.links.howItWorks'), path: Routes.HOW_IT_WORKS_PAGE },
    { label: t('layout.footer.links.cryptos'), path: Routes.LANDING_PAGE, sectionId: AVAILABLE_CRYPTOS_ID },
    { label: t('layout.footer.links.features'), path: Routes.LANDING_PAGE, sectionId: ATTRACTIVE_FEATURES_ID },
    { label: t('layout.footer.links.testimonials'), path: Routes.LANDING_PAGE, sectionId: TESTIMONIALS_ID },
    { label: t('layout.footer.links.knowledge'), path: Routes.LANDING_PAGE, sectionId: KNOWLEDGE_ID },
  ];

  const legal = [
    { label: t('layout.footer.legal.termsOfUse'), path: Routes.TERMS_OF_USE_PAGE },
    { label: t('layout.footer.legal.termsAndConditions'), path: Routes.TERMS_AND_CONDITIONS_PAGE },
    { label: t('layout.footer.legal.privacyPolicy'), path: Routes.PRIVACY_POLICY_PAGE },
    { label: t('layout.footer.legal.cookiePolicy'), path: Routes.COOKIE_POLICY_PAGE },
  ];

  return (
    <div className="footer-sections display-flex flex-wrap gap-30">
      <section className="crypto">
        <Text variant="content1" color="secondary" weight={700} className="mb-30" align="left">
          {t('layout.footer.crypto.title')}
        </Text>
        <Text variant="caption1" align="left">
          {t('layout.footer.crypto.subtitle')}
        </Text>
      </section>

      <section className="links">
        <Text variant="content1" color="secondary" weight={700} className="mb-30" align="left">
          {t('layout.footer.links.title').toUpperCase()}
        </Text>
        {links.map((item) => (
          <Link key={item.label} to={item.path} state={{ sectionId: item.sectionId }}>
            <Text variant="caption1" align="left" className="mb-10 as-link">
              {item.label}
            </Text>
          </Link>
      ))}
    </section>

      <section className="legal">
        <Text variant="content1" color="secondary" weight={700} className="mb-30" align="left">
          {t('layout.footer.legal.title').toUpperCase()}
        </Text>
        {legal.map((item) => (
          <Link key={item.label} to={item.path}>
            <Text
              key={item.label}
              variant="caption1"
              align="left"
              className="mb-10 as-link"
            >
              {item.label}
            </Text>
          </Link>

        ))}
      </section>

      <section className="newsletter">
        <Text variant="content1" color="secondary" weight={700} className="mb-30" align="left">
          {t('layout.footer.newsletter.title').toUpperCase()}
        </Text>
        <Text variant="content2" align="left" hasOpacity className="mb-20">
          {`${t('layout.footer.newsletter.over')} 2500 ${t('layout.footer.newsletter.peopleHaveSubscribed')}`}
        </Text>

        <div className="newsletter-form mb-10">
          <InputField
            value={email}
            onChange={setEmail}
            placeholder="Enter your email"
            lightMode
          />

          <Button
            label="Subscribe"
            onClick={() => {}}
            size='sm'
          />
        </div>

        <Text variant="caption2" align="left" hasOpacity className="mb-20">
          {t('layout.footer.newsletter.doNotSellAndSpam')}
        </Text>
      </section>
    </div>
  );
};
