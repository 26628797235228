import { ReactNode, useState, useMemo } from 'react';
import { AppContext } from './AppContext';
import { CryptoValue, IdentityMethod } from '../constants';
import { useWindowSize } from '../hooks';

export const AppProvider = ({ children }: { children: ReactNode }) => {
  const [ width] = useWindowSize();
  const [
    selectedCrypto,
    setSelectedCrypto
  ] = useState(CryptoValue.BTC);
  const [
    amountValue,
    setAmountValue
  ] = useState(0);
  const [
    selectedIdentityMethod,
    setSelectedIdentityMethod
  ] = useState<IdentityMethod>();

  const value = useMemo(() => {
    return {
      selectedCrypto,
      setSelectedCrypto,
      amountValue,
      setAmountValue,
      selectedIdentityMethod,
      setSelectedIdentityMethod,
      screenWidth: width
    };
  }, [
    selectedCrypto,
    setSelectedCrypto,
    amountValue,
    setAmountValue,
    selectedIdentityMethod,
    setSelectedIdentityMethod,
    width
  ]);


  return (
    <AppContext.Provider value={value}>
      {children}
    </AppContext.Provider>
  );
};