import { useTranslation } from 'react-i18next';
import { Text, Icon } from '../../../components';
import './FooterSocials.scss';


export const FooterSocials = () => {
  const { t } = useTranslation();

  const social = [
    { icon: <Icon className="icon-youtube" size={18} />, href: '#', name: 'youtube' },
    { icon: <Icon className="icon-linkedin" size={18} />, href: '#', name: 'linkedin' },
    { icon: <Icon className="icon-facebook" size={18} />, href: '#', name: 'facebook' },
    { icon: <Icon className="icon-bitcoin" size={18} />, href: '#', name: 'bitcoin' },
  ];

  return (
    <div
      className="socials display-flex flex-wrap space-between gap-20 align-center mt-20"
    >
      <div className="privacy-and-terms display-flex gap-20">
        <a href="#">
          <Text variant="caption2" align="left">
            {t('layout.footer.privacyAndTerms')}
          </Text>
        </a>
        <a href="#">
          <Text variant="caption2" align="left">
            {t('layout.footer.contactUs')}
          </Text>
        </a>
      </div>

      <div className="copy-right display-flex">
        <Text variant="caption2" align="left">
          {`${t('layout.footer.copyright')} 2024`}
        </Text>
      </div>

      <div className="social-networks display-flex gap-10">
        {social.map((item) => (
          <a href={item.href} key={item.name}>
            <div>
              {item.icon}
            </div>
          </a>
        ))}
      </div>
    </div>
  );
};