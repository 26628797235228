import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Text } from '../Text/Text';

type Props = {
  color?: "primary" | "secondary" | "tertiary"
}

const MemoComponent = function ({ color = "primary" }: Props) {
  const { t } = useTranslation();
  const sections = [
    {
      title: t('terms.agreementToTerms.title'),
      conditions: [
        { condition: t('terms.agreementToTerms.condition1') },
        { condition: t('terms.agreementToTerms.condition2') },
      ]
    },
    {
      title: t('terms.identityVerification.title'),
      conditions: [
        { condition: t('terms.identityVerification.condition1') },
        { condition: t('terms.identityVerification.condition2') },
        { condition: t('terms.identityVerification.condition3') },
      ]
    },
    {
      title: t('terms.transactionFees.title'),
      subtitle: t('terms.transactionFees.subtitle'),
      conditions: [
        { condition: t('terms.transactionFees.condition1') },
        { condition: t('terms.transactionFees.condition2') },
        { condition: t('terms.transactionFees.condition3') },
      ]
    },
    {
      title: t('terms.exchangeRates.title'),
      conditions: [
        { condition: t('terms.exchangeRates.condition1') },
        { condition: t('terms.exchangeRates.condition2') },
        { condition: t('terms.exchangeRates.condition3') },
      ]
    },
    {
      title: t('terms.paymentAuthorization.title'),
      conditions: [
        { condition: t('terms.paymentAuthorization.condition1') },
        { condition: t('terms.paymentAuthorization.condition2') },
      ]
    },
    {
      title: t('terms.riskAcknowledgement.title'),
      subtitle: t('terms.riskAcknowledgement.subtitle'),
      agreement: t('terms.riskAcknowledgement.agreement'),
      conditions: [
        { condition: t('terms.riskAcknowledgement.condition1') },
        { condition: t('terms.riskAcknowledgement.condition2') },
        { condition: t('terms.riskAcknowledgement.condition3') },
      ]
    },
    {
      title: t('terms.cardIssuerPolicies.title'),
      conditions: [
        { condition: t('terms.cardIssuerPolicies.condition1') },
        { condition: t('terms.cardIssuerPolicies.condition2') },
      ]
    },
    {
      title: t('terms.jurisdictionCompliance.title'),
      conditions: [
        { condition: t('terms.jurisdictionCompliance.condition1') },
        { condition: t('terms.jurisdictionCompliance.condition2') },
        { condition: t('terms.jurisdictionCompliance.condition3') },
      ]
    },
    {
      title: t('terms.privacyAndDataProtection.title'),
      conditions: [
        { condition: t('terms.privacyAndDataProtection.condition1') },
        { condition: t('terms.privacyAndDataProtection.condition2') },
        { condition: t('terms.privacyAndDataProtection.condition3') },
      ]
    },
    {
      title: t('terms.disputeResolution.title'),
      conditions: [
        { condition: t('terms.disputeResolution.condition1') },
        { condition: t('terms.disputeResolution.condition2') },
      ]
    },
    {
      title: t('terms.platformLiabilityLimitation.title'),
      conditions: [
        { condition: t('terms.platformLiabilityLimitation.condition1') },
        { condition: t('terms.platformLiabilityLimitation.condition2') },
      ]
    },
    {
      title: t('terms.modificationsToTerms.title'),
      conditions: [
        { condition: t('terms.modificationsToTerms.condition1') },
        { condition: t('terms.modificationsToTerms.condition2') },
      ]
    },
    {
      title: t('terms.refundsAndCancellations.title'),
      conditions: [
        { condition: t('terms.refundsAndCancellations.condition1') },
        { condition: t('terms.refundsAndCancellations.condition2') },
      ]
    },
  ];

  return (
    <div className="terms-and-conditions">
      <Text color={color} variant="title2" align="center" weight={700} className="mb-40">
        {t('terms.title')}
      </Text>

      <Text color={color} variant="caption2" align="left" className="mb-30">
        {t('terms.description')}
      </Text>

      {sections.map((section, index) => (
        <section key={section.title} className="mb-40">
          <Text
            color={color}
            variant="content1"
            align="left"
            weight={700}
            className="mb-5"
            key={section.title}
          >
            {`${index + 1}. ${section.title}`}
          </Text>

          {section.subtitle && (
            <Text
              color={color}
              variant="caption2"
              align="left"
              className="mb-5"
            >
              {section.subtitle}
            </Text>
          )}

          <ul className="section-list">
            {section.conditions.map((item, index) => (
              <li key={item.condition}>
                <Text color={color} variant="caption2" align="left">
                  {item.condition}
                </Text>
              </li>
            ))}
          </ul>

          {section.agreement && (
            <Text
              color={color}
              variant="caption2"
              align="left"
              className="mt-5"
            >
              {section.agreement}
            </Text>
          )}

        </section>
      ))}

      <Text color={color} variant="caption2" align="left" className="pb-30">
        {t('terms.closure')}
      </Text>
    </div>
  );
};

export const TermsAndConditions = memo(MemoComponent);