import { ChangeEvent, memo, useCallback, useRef, useState } from 'react';
import Webcam from 'react-webcam';
import { useTranslation } from 'react-i18next';
import { Button, Icon, Text } from '../../../components';
import './UploadKYCDocument.scss';

type Props = {
  id: string;
  onContinue: () => void;
  onCancel: () => void;
}

function MemoComponent({ id, onContinue, onCancel }: Props) {
  const webcamRef = useRef<Webcam>(null);
  const { t } = useTranslation();

  const [ capturedImg, setCapturedImg ] = useState<string | null>(null);
  const [ uploadedImg, setUploadedImg ] = useState(null);
  const [ isWebcamOpen, setIsWebcamOpen ] = useState<boolean>(false);

  const resetImages = useCallback(() => {
    setCapturedImg(null);
    setUploadedImg(null);
  }, []);

  // open webcam and reset images
  const onOpenWebcam = useCallback(() => {
    setIsWebcamOpen(true);
    resetImages();
  }, [resetImages]);

  // close webcam and reset images - initial state
  const onTryAgain = useCallback(() => {
    setIsWebcamOpen(false);
    resetImages();
  }, [resetImages]);

  // set capture img and reset everything
  const onCaptureImg = useCallback(() => {
    const imageBase64 = webcamRef.current?.getScreenshot();
    setCapturedImg(imageBase64 || null);
    setUploadedImg(null);
    setIsWebcamOpen(false);
  }, []);

  // set uploaded img and reset everything
  const onUpload = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    // @ts-ignore
    setUploadedImg(event.target.files[0]);
    setCapturedImg(null);
    setIsWebcamOpen(false);
  }, []);

  return (
    <div id={id} className="upload-kyc-document kyc-flow-content">
      <Text variant="title2" weight={700} color="secondary">
        {t('kyc.secondStep.photoIdVerification')}
      </Text>

      <div className="upload-section">
        {!isWebcamOpen && !capturedImg && !uploadedImg && (
          <Icon className="icon-scaner" size={60} color="secondary"/>
        )}

        {capturedImg && (
          <img src={capturedImg} style={{ width: '100%', maxWidth: '100%' }} alt="identity card"/>
        )}

        {uploadedImg && (
          <img
            src={URL.createObjectURL(uploadedImg)}
            style={{ width: '100%', maxWidth: '100%' }}
            alt="identity card"
          />
        )}

        {isWebcamOpen && (
          <Webcam
            ref={webcamRef}
            audio={false}
            screenshotFormat="image/jpeg"
            style={{ width: '100%', maxWidth: '100%' }}
            videoConstraints={{ aspectRatio: (450 / 260) }}
          />
        )}
      </div>

      <Text variant="content1" color="tertiary">
        {t('kyc.secondStep.scanTypeOfId')}
      </Text>

      {!isWebcamOpen && (
        <>
          {!capturedImg && !uploadedImg && (
            <>
              <Button label={t('layout.button.takePhoto')} onClick={onOpenWebcam}/>
              <label className="upload-button cursor-pointer">
                <Text variant="caption2" color="secondary" weight={600}>
                  {t('layout.button.upload')}
                </Text>
                <input
                  type="file"
                  name="img"
                  onChange={onUpload}
                  accept="image/*"
                />
              </label>
            </>
          )}

          {(capturedImg || uploadedImg) && <Button label={t('layout.button.continue')} onClick={onContinue}/>}
        </>
      )}

      {isWebcamOpen && <Button label={t('layout.button.capture')} onClick={onCaptureImg}/>}

      {(isWebcamOpen || capturedImg || uploadedImg) && (
        <Button
          label={t('layout.button.tryAgain')}
          onClick={onTryAgain}
          variant="outlined"
        />
      )}

      <Button
        label={t('layout.button.cancel')}
        onClick={onCancel}
        variant="outlined"
      />
    </div>
  );
}

export const UploadKYCDocument = memo(MemoComponent);