import { memo, useMemo } from 'react';
import { TRANSACTION_STATUS, TRANSACTION_TYPE } from '../../../../types';
import { Text } from '../../../Text/Text';
import { formatDateAndHour, hideCardNumber } from '../../../../utils';
import './MobileExpandedRow.scss';

type Props = {
  transaction: TRANSACTION_TYPE
}

const MemoComponent = function ({ transaction }: Props) {
  const formattedDate = useMemo(() => {
    return formatDateAndHour(transaction.date);
  }, [ transaction.date ]);

  const hiddenCardNumber = useMemo(() => {
    return hideCardNumber(transaction.cardNumber);
  }, [ transaction.cardNumber ]);

  return (
    <div className="mobile-expanded-row">
      <div className="display-flex space-between mb-20">
        <Text variant="content2" hasOpacity>Date</Text>
        <Text variant="content2">
          {`${formattedDate.date} / ${formattedDate.hour}`}
        </Text>
      </div>
      <div className="display-flex space-between mb-20">
        <Text variant="content2" hasOpacity>Status</Text>
        <Text
          variant="content2"
          color={transaction.status === TRANSACTION_STATUS.SUCCESSFUL ? 'success' : 'error'}
        >
          {transaction.status}
        </Text>
      </div>
      <div className="display-flex space-between mb-20">
        <Text variant="content2" hasOpacity>Card Type</Text>
        <Text variant="content2">
          {transaction.cardType}
        </Text>
      </div>
      <div className="display-flex space-between mb-20">
        <Text variant="content2" hasOpacity>Card Number</Text>
        <Text variant="content2">
          {hiddenCardNumber}
        </Text>
      </div>
      <div className="display-flex space-between">
        <Text variant="content2" hasOpacity>Card Holder</Text>
        <Text variant="content2">
          {`${transaction.cardHolderFirstName}, ${transaction.cardHolderLastName}`}
        </Text>
      </div>
    </div>
  );
};

export const MobileExpandedRow = memo(MemoComponent);