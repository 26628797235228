import { memo, useState, useRef, useEffect, useCallback } from 'react';
import { Button, TermsAndConditions } from '../../../components';
import { useTranslation } from 'react-i18next';
import './AcceptTermsAndConditions.scss';

type Props = {
  id: string;
  onProceed: () => void;
}

function MemoComponent({ id, onProceed }: Props) {
  const { t } = useTranslation();
  const [isDisabled, setIsDisabled] = useState(true);
  const termsRef = useRef<HTMLDivElement>(null);

  const handleScroll = useCallback(() => {
    const terms = termsRef.current;
    if (terms) {
      if (terms.scrollTop + terms.clientHeight >= terms.scrollHeight) {
        setIsDisabled(false);
        terms?.removeEventListener('scroll', handleScroll);
      } else {
        setIsDisabled(true);
      }
    }
  }, []);

  useEffect(() => {
    const terms = termsRef.current;
    terms?.addEventListener('scroll', handleScroll);
    return () => {
      terms?.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);

  return (
    <div
      id={id}
      className="accept-terms-and-conditions kyc-flow-content"
    >
      <div className="content" ref={termsRef}>
        <TermsAndConditions color={"tertiary"} />
      </div>

      <Button
        label={t('layout.button.agreeAndContinue')}
        onClick={onProceed}
        isDisabled={isDisabled}
      />
    </div>
  );
}

export const AcceptTermsAndConditions = memo(MemoComponent);