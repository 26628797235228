import { memo, useContext, useEffect, useState } from 'react';
import { SelectKYCMethod } from './SelectKYCMethod/SelectKYCMethod';
import { AppContext } from '../../context';
import { UploadKYCDocument } from './UploadKYCDocument/UploadKYCDocument';
import { KYCVerified } from './KYCVerified/KYCVerified';
import { KYCFailed } from './KYCFailed/KYCFailed';
import { CryptoTrend } from '../../components';
import { AcceptTermsAndConditions } from './AcceptTermsAndConditions/AcceptTermsAndConditions';
import "./KYCFlow.scss";

type Props = {
  id: string;
  onCancel: () => void;
}

function MemoComponent({ id, onCancel }: Props) {
  const [currentStep, setCurrentStep] = useState(1);
  const { selectedIdentityMethod } = useContext(AppContext);

  // Automatically scrolls to top whenever current step changes
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [currentStep]);

  useEffect(() => {
    if (!selectedIdentityMethod) {
      setCurrentStep(1);
    }
  }, [selectedIdentityMethod]);

  return (
    <div id={id} className="kyc-flow">
      <div className="kyc-flow-container">
        { currentStep === 1 && (
          <SelectKYCMethod
            id="select-kyc-method"
            onNext={() => setCurrentStep(2)}
          />
        )}

        { currentStep === 2 && (
          <UploadKYCDocument
            id="upload-kyc-document"
            onContinue={() => setCurrentStep(3)}
            onCancel={onCancel}
          />
        )}

        { currentStep === 3 && (
          <KYCVerified
            id="kyc-verified"
            onProceed={() => setCurrentStep(4)}
          />
        )}

        { currentStep === 4 && (
          <CryptoTrend id="crypto-trend-section" onCheckout={() => setCurrentStep(5)}/>
        )}

        { currentStep === 5 && (
          <AcceptTermsAndConditions id="accept-terms-and-conditions" onProceed={() => setCurrentStep(6)}/>
        )}

        { currentStep === 6 && (
          <KYCFailed id="kyc-failed" onTryAgain={() => setCurrentStep( 1)}/>
        )}
      </div>
    </div>
  );
}

export const KYCFlow = memo(MemoComponent);