import React, { useCallback, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Text, Carousel } from '../../components';
import { CryptoCard, CryptoCardType } from './CryptoCard/CryptoCard';
import { Routes } from '../../router';
import { CryptoValue } from '../../constants';
import { AppContext } from '../../context';

import bitcoinThumbnail from '../../assets/crypto/thumbnails/bitcoin.png';
import litecoinThumbnail from '../../assets/crypto/thumbnails/litecoin.png';
import ethereumThumbnail from '../../assets/crypto/thumbnails/ethereum.png';

export const AvailableCryptos = ({ id }: { id: string }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { setSelectedCrypto } = useContext(AppContext);
  const cards: CryptoCardType[] = [
    {
      logo: bitcoinThumbnail,
      title: CryptoValue.BTC,
      description: t('landingPage.cryptoCard.bitcoin.description'),
      price: 4370000,
      url: 'https://en.wikipedia.org/wiki/Bitcoin',
    },
    {
      logo: litecoinThumbnail,
      title: CryptoValue.LTC,
      description: t('landingPage.cryptoCard.litecoin.description'),
      price: 15800,
      url: 'https://en.wikipedia.org/wiki/Litecoin'
    },
    {
      logo: ethereumThumbnail,
      title: CryptoValue.ETH,
      description: t('landingPage.cryptoCard.ethereum.description'),
      price: 300000,
      url: 'https://en.wikipedia.org/wiki/Ethereum'
    }
  ];

  const onClick = useCallback((card: CryptoCardType) => {
    setSelectedCrypto(card.title);
    navigate(`${Routes.BUY_PAGE}`);
  }, [navigate, setSelectedCrypto]);

  return (
    <section id={id} className="mt-40 mb-40 w-70">
      <Text variant="title2" weight={700} className="mb-10">
        {t('landingPage.availableCryptos.title')}
      </Text>

      <Text variant="content1" hasOpacity className="mb-30">
        {t('landingPage.availableCryptos.subtitle')}
      </Text>

      <Carousel>
        {cards.map((card) => (
          <CryptoCard key={card.title} card={card} onClick={() => onClick(card)}/>
        ))}
      </Carousel>
    </section>
  );
};