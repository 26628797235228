import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Icon, Text } from '../../components';
import { FeatureCard, FeatureCardType } from './FeatureCard/FeatureCard';
import etherium2 from '../../assets/crypto/coins/Etherium-2.png';
import monero1 from '../../assets/crypto/coins/Monero-1.png';
import './AttractiveFeatures.scss';

export const AttractiveFeatures = memo(
  function Features({ id }: { id: string }) {
    const { t } = useTranslation();
    const features: FeatureCardType[] = [
      {
        icon: <Icon className="icon-trophy-outline" size={65} color="secondary"/>,
        title: t('landingPage.feature.weeklyContest.title'),
        description: t('landingPage.feature.weeklyContest.description')
      },
      {
        icon: <Icon className="icon-wallet-outline" size={65} color="secondary"/>,
        title: t('landingPage.feature.lesCommission.title'),
        description: t('landingPage.feature.lesCommission.description')
      },
      {
        icon: <Icon className="icon-lock-outline" size={65} color="secondary"/>,
        title: t('landingPage.feature.safeAndSecure.title'),
        description: t('landingPage.feature.safeAndSecure.description')
      },
      {
        icon: <Icon className="icon-dolar-sign" size={65} color="secondary"/>,
        title: t('landingPage.feature.instantWithdrawProcess.title'),
        description: t('landingPage.feature.instantWithdrawProcess.description')
      },
      {
        icon: <Icon className="icon-giftcard-outline" size={65} color="secondary"/>,
        title: t('landingPage.feature.monthlyCommition.title'),
        description: t('landingPage.feature.monthlyCommition.description')
      },
      {
        icon: <Icon className="icon-headset-mic-outline" size={65} color="secondary"/>,
        title: t('landingPage.feature.supportTeam.title'),
        description: t('landingPage.feature.supportTeam.description')
      }
    ];

    return (
      <section id={id} className="attractive-features mt-40 mb-40 w-70">
        <Text variant="title2" weight={700} className="mb-10">
          {t('landingPage.attractiveFeatures.title')}
        </Text>

        <Text variant="content1" hasOpacity className="mb-30">
          {t('landingPage.attractiveFeatures.subtitle')}
        </Text>

        <div className="display-flex flex-wrap align-center gap-20">
          {features.map((item: FeatureCardType) => (
            <FeatureCard key={item.title} feature={item}/>
          ))}
        </div>

        <img src={monero1} alt="monero" className="coin img-top-left"/>
        <img src={etherium2} alt="litecoin" className="coin img-top-right"/>
      </section>
    );
  }
);