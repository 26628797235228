import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { AppLanguage } from './i18n.config';

void i18n.use(initReactI18next).init({
  // #TODO get current language from local storage
  lng: AppLanguage.ENGLISH,
  fallbackLng: AppLanguage.ENGLISH,
  interpolation: {
    escapeValue: false,
  },
  resources: {
    [AppLanguage.ENGLISH]: {
      translation: require('./translations/en.json')
    },
    [AppLanguage.SPANISH]: {
      translation: require('./translations/es.json')
    },
  },
});

export default i18n;