import React from 'react';
import { useTranslation } from 'react-i18next';
import { Text } from '../Text/Text';
import { ArticleCardType, ArticleCard } from './ArticleCard/ArticleCard';
import article1 from '../../assets/articles/article1.png';
import article2 from '../../assets/articles/article2.png';
import article3 from '../../assets/articles/article3.png';
import article4 from '../../assets/articles/article4.png';
import monero1 from '../../assets/crypto/coins/Monero-1.png';
import './Knowledge.scss';


export const Knowledge = ({ id }: { id: string }) => {
  const { t } = useTranslation();

  const articles: ArticleCardType[] = [
    {
      title: 'Cryptocurrency Basics: Pros, Cons and How It Works',
      content: 'Cryptocurrency (or “crypto”) is a digital currency that can be used to buy goods and services or traded for a profit.',
      image: article1,
      url: 'https://www.nerdwallet.com/article/investing/cryptocurrency',
    },
    {
      title: 'Cryptocurrency',
      content: 'A cryptocurrency is a digital currency designed to work as a medium of exchange through a computer network that is not reliant on any central authority, such as a government or bank, to uphold or maintain it',
      image: article3,
      url: 'https://en.wikipedia.org/wiki/Cryptocurrency'
    },
    {
      title: ' Short History of Cryptocurrencies',
      content: 'Cryptocurrencies are digital currencies that use cryptography – a technique for encoding data to make it unreadable to anyone who lacks a password.',
      image: article2,
      url: 'https://kriptomat.io/cryptocurrencies/history-of-cryptocurrency'
    },
    {
      title: 'Gambling',
      content: 'Gambling (also known as betting or gaming) is the wagering of something of value ("the stakes") on a random event with the intent of winning something else of value, where instances of strategy are discounted.',
      image: article4,
      url: 'https://en.wikipedia.org/wiki/Gambling'
    }
  ];

  return (
    <section id={id} className="knowledge mt-40 mb-40 w-70">
      <Text variant="title2" weight={700} className="mb-10">
        {t('landingPage.knowledge.title')}
      </Text>

      <Text variant="content1" hasOpacity className="mb-30">
        {t('landingPage.knowledge.subtitle')}
      </Text>

      <div className="display-flex flex-wrap align-center gap-20">
        {articles.map((article) => (
          <ArticleCard article={article} key={article.title}/>
        ))}
      </div>

      <img src={monero1} alt="monero" className="coin img-bottom-left"/>
    </section>
  );
};