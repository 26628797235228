export enum TRANSACTION_STATUS {
  SUCCESSFUL = "Successful",
  DECLINED = "Declined"
}

export type TRANSACTION_TYPE = {
  id: string,
  date: string,
  amount: number,
  status: TRANSACTION_STATUS,
  cardType: string,
  cardNumber: string,
  cardHolderFirstName: string,
  cardHolderLastName: string,
}