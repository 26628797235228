import { useCallback, useEffect, useState } from 'react';
import { Text, Icon } from '../../components';
import { SelectableOption } from '../../types';
import './Dropdown.scss';

type DropdownProps = {
  value: SelectableOption;
  onChange: (option: SelectableOption) => void;
  options: SelectableOption[];
  size?: 'sm' | 'md' | 'lg';
  className?: string;
  lightMode?: boolean;
}

export const Dropdown = (props: DropdownProps) => {
  const {
    value,
    onChange,
    options,
    size = 'lg',
    className,
    lightMode
  } = props;
  const lightModeClass = lightMode ? 'light-mode' : '';

  const [ isOpen, setIsOpen ] = useState(false);
  const hideOptions = () => setIsOpen(false);
  const changeValue = (option: SelectableOption) => {
    onChange(option);
    hideOptions();
  };

  const onClickOutside = useCallback((event: any) => {
    if (!event.target.closest('.dropdown')) {
      hideOptions();
    }
  }, []);

  useEffect(() => {
    window.addEventListener('click', onClickOutside);
    return () => {
      window.removeEventListener('click', onClickOutside);
    };
  }, [onClickOutside]);


  return (
    <div className={`${className} ${lightModeClass} size-${size} dropdown`}>
      <div
        className={`dropdown-value display-flex align-center gap-10 justify-center cursor-pointer`}
        onClick={() => setIsOpen(!isOpen)}
      >
        <Text
          variant="caption2"
          weight={600}
          color={lightMode ? 'secondary' : 'primary'}
        >
          {value.label}
        </Text>
        {isOpen && (<Icon className="icon-chevron-up" size={12}/>)}
        {!isOpen && (<Icon className="icon-chevron-down" size={12}/>)}
      </div>

      {isOpen && (
        <ul className={`dropdown-options size-${size}`}>
          {options.map((option) => (
            <li key={option.id} onClick={() => changeValue(option)}>
              <Text
                variant="caption2"
                weight={600}
                align="center"
                color={lightMode ? 'secondary' : 'primary'}
              >
                {option.label}
              </Text>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};