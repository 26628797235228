import { useEffect, useState } from 'react';
import { Icon, Text } from '../.././../components';
import './BurgerMenu.scss';
import { Link } from 'react-router-dom';

export const BurgerMenu = ({ navItems, user, language }: any) => {
  const [ isOpen, setIsOpen ] = useState(false);

  const onClickOutside = (event: any) => {
    if (!event.target.closest('.burger-menu')) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    window.addEventListener('click', onClickOutside);
    return () => {
      window.removeEventListener('click', onClickOutside);
    };
  }, []);

  return (
    <div className="burger-menu">
      <div className="burger-menu-icon color-purple cursor-pointer">
        <Icon
          className="icon-burger-menu"
          size={32}
          onClick={() => setIsOpen(!isOpen)}
          color="secondary"
        />
      </div>
      {isOpen && (
        <ul className="burger-menu-list">
          {navItems.map((item: any) => (
            <li key={item.label}>
              <Link key={item.label} to={item.path} state={{ sectionId: item.sectionId }}>
                <Text variant="content2" className="as-link" align="right">
                  {item.label}
                </Text>
              </Link>
            </li>
          ))}
          <li>
            <Text
              variant="content2"
              align="right"
              onClick={user.onClick}
              className="cursor-pointer"
            >
              {user.value}
            </Text>
          </li>
          <li>
            <Text
              variant="content2"
              align="right"
              onClick={language.onClick}
              className="cursor-pointer"
            >
              {language.value}
            </Text>
          </li>

        </ul>
      )}
    </div>
  );
};