export const CREDIT_TO_CRYPTO_ID = 'credit-to-crypto-section';
export const AVAILABLE_CRYPTOS_ID = 'available-cryptos-section';
export const ATTRACTIVE_FEATURES_ID = 'attractive-features-section';
export const TESTIMONIALS_ID = 'testimonials-section';
export const KNOWLEDGE_ID = 'knowledge-section';

export const scrollToElement = (elementId: string) => {
  const targetSection = document.getElementById(elementId);
  if (targetSection) {
    window.scrollTo({
      top: targetSection?.offsetTop - 120, // header offset
      behavior: 'smooth'
    })
  }
}