import { createContext } from 'react';
import { CryptoValue, IdentityMethod } from '../constants';


export type AppContextType = {
  selectedCrypto: CryptoValue
  setSelectedCrypto: (crypto: CryptoValue) => void
  amountValue: number
  setAmountValue: (value: number) => void;
  selectedIdentityMethod: IdentityMethod | undefined
  setSelectedIdentityMethod: (identityMethod: IdentityMethod | undefined) => void
  screenWidth: number;
};

// @ts-ignore
export const AppContext = createContext<AppContextType>({
  selectedCrypto: CryptoValue.BTC,
  setSelectedCrypto: (crypto: CryptoValue) => null,
  amountValue: 0,
  setAmountValue: (value: number) => null,
  selectedIdentityMethod: undefined,
  setSelectedIdentityMethod: (identityMethod: IdentityMethod | undefined) => null,
  screenWidth: 1200
});