import { memo } from 'react';
import { Text } from '../../../components';
import { useTranslation } from 'react-i18next';

type colAlign = "left" | "right" | "center";

const MemoComponent = function () {
  const { t } = useTranslation();
  const tableColumns = [
    { label: t('transaction.id'), className: 'cell-id', align: 'center' },
    { label: t('transaction.date'), className: 'cell-date', align: 'center' },
    { label: t('transaction.amount'), className: 'cell-amount', align: 'right' },
    { label: t('transaction.status'), className: 'cell-status', align: 'center' },
    { label: t('transaction.cardType'), className: 'cell-card-type', align: 'center' },
    { label: t('transaction.cardNumber'), className: 'cell-card-number', align: 'center' },
    { label: t('transaction.cardHolder'), className: 'cell-card-holder', align: 'center' },
  ];

  return (
    <div className="t-table-header">
      <div className="t-table-row">
        {tableColumns.map((column) => (
          <div className={`t-table-cell ${column.className}`} key={column.label}>
            <Text variant="caption2" weight={700} align={column.align as colAlign}>
              {column.label}
            </Text>
          </div>
        ))}

        <div className="t-table-cell cell-toggle-row">
          <div></div>
        </div>
      </div>
    </div>
  );
};

export const TableHeader = memo(MemoComponent);