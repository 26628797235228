import { memo, useContext, useEffect, useMemo, useState } from 'react';
import { TRANSACTION_STATUS, TRANSACTION_TYPE } from '../../../types';
import { Icon, Text } from '../../../components';
import { AppContext } from '../../../context';
import { formatDateAndHour, hideCardNumber, numberAsUSDFormatted } from '../../../utils';
import { MobileExpandedRow } from './MobileExpandedRow/MobileExpandedRow';

type Props = {
  transaction: TRANSACTION_TYPE,
}

const MemoComponent = function ({ transaction }: Props) {
  const { screenWidth } = useContext(AppContext);
  const [ isExpanded, setIsExpanded ] = useState(false);

  useEffect(() => {
    if (screenWidth > 900) {
      setIsExpanded(false);
    }
  }, [ screenWidth ]);

  const formattedAmount = useMemo(() => {
    return numberAsUSDFormatted(transaction.amount);
  }, [ transaction.amount ]);

  const hiddenCardNumber = useMemo(() => {
    return hideCardNumber(transaction.cardNumber);
  }, [ transaction.cardNumber ]);

  const formattedDate = useMemo(() => {
    return formatDateAndHour(transaction.date);
  }, [ transaction.date ]);

  return (
    <>
      <div className="t-table-row">
        <div className="t-table-cell cell-id">
          <Text variant="caption2" align="center">
            {transaction.id}
          </Text>
        </div>
        <div className="t-table-cell cell-date">
          <Text variant="caption2" align="center">
            {formattedDate.date}
          </Text>
          <Text variant="caption2" align="center">
            {formattedDate.hour}
          </Text>
        </div>
        <div className="t-table-cell cell-amount">
          <Text variant="caption2" align="right">
            {formattedAmount}
          </Text>
        </div>
        <div className="t-table-cell cell-status">
          <Text
            variant="caption2"
            align="center"
            color={transaction.status === TRANSACTION_STATUS.SUCCESSFUL ? 'success' : 'error'}
          >
            {transaction.status}
          </Text>
        </div>
        <div className="t-table-cell cell-card-type">
          <Text variant="caption2" align="center">
            {transaction.cardType}
          </Text>
        </div>
        <div className="t-table-cell cell-card-number">
          <Text variant="caption2" align="center">
            {hiddenCardNumber}
          </Text>
        </div>
        <div className="t-table-cell cell-card-holder">
          <Text variant="caption2" align="center">
            {`${transaction.cardHolderFirstName}, ${transaction.cardHolderLastName}`}
          </Text>
        </div>
        <div className="t-table-cell cell-toggle-row">
          <Icon
            className={isExpanded ? 'icon-chevron-up' : 'icon-chevron-down'}
            containerClassName="toggle-row"
            color="tertiary"
            size={16}
            onClick={() => setIsExpanded(!isExpanded)}
          />
        </div>
      </div>

      {isExpanded && <MobileExpandedRow transaction={transaction}/>}
    </>
  );
};

export const TableRow = memo(MemoComponent);