import { ChangeEvent, memo, useCallback } from 'react';
import { Text } from '../../components';
import { SelectableOption } from '../../types';
import "./RadioInput.scss";

type Props = {
  value: SelectableOption,
  isSelected: boolean,
  onSelect: (value: SelectableOption) => void,
  className?: string,
  size?: 'sm' | 'md' | 'lg';
}

const MemoComponent = function({
   value,
   isSelected,
   onSelect,
   className,
   size = 'lg'
}: Props) {
  const checkedClass = isSelected ? 'checked' : '';
  const onChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    onSelect(value)
  }, [onSelect, value])

  return (
    <div
      className={`radio-input ${className} size-${size} ${checkedClass}`}
      onClick={() => onSelect(value)}
    >
      <input type="radio" value={value.id} onChange={onChange} checked={isSelected} />
      <Text variant="content2" color="secondary">{value.label}</Text>
      <div style={{width: "32px" }}></div>
    </div>
  );
};

export const RadioInput = memo(MemoComponent)