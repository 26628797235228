import { useCallback } from 'react';
import './InputField.scss';

type InputProps = {
  value: string;
  onChange: (value: string) => void;
  placeholder?: string;
  className?: string;
  disabled?: boolean;
  size?: 'sm' | 'md' | 'lg';
  onBlur?: () => void;
  lightMode?: boolean;
};

export const InputField = (props: InputProps) => {
  const {
    value,
    onChange,
    placeholder,
    className,
    disabled,
    size,
    onBlur,
    lightMode
  } = props;
  const sizeClass = size ? `size-${size}` : 'size-lg';
  const lightModeClass = lightMode ? `light-mode` : '';

  const onValueChange = useCallback((event: { target: { value: string }; }) => {
    onChange(event.target.value);
  }, [onChange]);

  return (
    <input
      value={value}
      onChange={onValueChange}
      placeholder={placeholder}
      className={`input-field ${className} ${sizeClass} ${lightModeClass}`}
      disabled={disabled}
      onBlur={onBlur}
    />
  );
};