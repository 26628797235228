import { memo } from 'react';
import { TRANSACTION_TYPE } from '../../types';
import { TableHeader } from './TableHeader/TableHeader';
import { TableRow } from './TableRow/TableRow';
import './TransactionsTable.scss';

type Props = {
  transactions: TRANSACTION_TYPE[];
}

const MemoComponent = function ({ transactions }: Props) {
  return (
    <div className="transactions-table">
      <div className="t-table">
        <TableHeader/>
        <div className="t-table-body">
          {transactions.map((transaction: TRANSACTION_TYPE, index: number) => (
            <TableRow transaction={transaction} key={transaction.id}/>
          ))}
        </div>
      </div>
    </div>
  );
};

export const TransactionsTable = memo(MemoComponent);
