import { format, parseISO } from 'date-fns';

export function hideCardNumber(cardNumber: string) {
  return `**** **** ${cardNumber.slice(-4)}`;
}

export function formatDateAndHour(date: string) {
  const dateAsDate = parseISO(date);
  return {
    date: format(dateAsDate, "MMM dd yyyy"),
    hour: format(dateAsDate, "hh:mm a"),
  };
}