import React, { lazy, Suspense, useEffect } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import { AppProvider } from './context';
import { Footer, Header } from './components';
import './i18n/i18n';
import { Routes as LocalRoutes } from './router';

import './App.scss';
import './styles/Flexboxstyle.scss';
import './styles/Commonstyle.scss';
import './styles/Fonts.scss';

const LazyLandingPage = lazy(() =>
  import('./pages/LandingPage/LandingPage').then((module) => ({
    default: module.LandingPage
  }))
);

const LazyBuyPage = lazy(() =>
  import('./pages/BuyPage/BuyPage').then((module) => ({
    default: module.BuyPage
  }))
);

const LazyTransactionsPage = lazy(() =>
  import('./pages/TransactionsPage/TransactionsPage').then((module) => ({
    default: module.TransactionsPage
  }))
);

const LazyTermsAndConditionsPage = lazy(() =>
  import('./pages/TermsAndConditionsPage/TermsAndConditionsPage').then((module) => ({
    default: module.TermsAndConditionsPage
  }))
);

const LazyHowItWorksPage = lazy(() =>
  import('./pages/HowItWorksPage/HowItWorksPage').then((module) => ({
    default: module.HowItWorksPage
  }))
);

const LazyCookiePolicyPage = lazy(() =>
  import('./pages/CookiePolicyPage/CookiePolicyPage').then((module) => ({
    default: module.CookiePolicyPage
  }))
);

const LazyPrivacyPolicyPage = lazy(() =>
  import('./pages/PrivacyPolicyPage/PrivacyPolicyPage').then((module) => ({
    default: module.PrivacyPolicyPage
  }))
);

const LazyTermsOfUsePage = lazy(() =>
  import('./pages/TermsOfUsePage/TermsOfUsePage').then((module) => ({
    default: module.TermsOfUsePage
  }))
);

const fallback = <div className="main-background-image"></div>;

function App() {
  // Extracts pathname property(key) from an object
  const { pathname } = useLocation();

  // Automatically scrolls to top whenever pathname changes
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [pathname]);

  return (
    <AppProvider>
      <div className="App">
        <Header/>

        <div className="application-content">
          <Suspense fallback={fallback}>
            <Routes>
              <Route path={LocalRoutes.LANDING_PAGE} element={<LazyLandingPage/>}/>
              <Route path={LocalRoutes.BUY_PAGE} element={<LazyBuyPage/>}/>
              <Route path={LocalRoutes.TRANSACTIONS_PAGE} element={<LazyTransactionsPage/>}/>
              <Route path={LocalRoutes.TERMS_AND_CONDITIONS_PAGE} element={<LazyTermsAndConditionsPage/>}/>
              <Route path={LocalRoutes.HOW_IT_WORKS_PAGE} element={<LazyHowItWorksPage/>}/>
              <Route path={LocalRoutes.COOKIE_POLICY_PAGE} element={<LazyCookiePolicyPage/>}/>
              <Route path={LocalRoutes.PRIVACY_POLICY_PAGE} element={<LazyPrivacyPolicyPage/>}/>
              <Route path={LocalRoutes.TERMS_OF_USE_PAGE} element={<LazyTermsOfUsePage/>}/>
            </Routes>
          </Suspense>
        </div>

        <Footer/>
      </div>
    </AppProvider>
  );
}

export default App;
