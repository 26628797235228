import { memo, ReactNode } from 'react';
import './Text.scss';

type TextProps = {
  children: ReactNode,
  variant:
    'title1' | // font-size: 64px
    'title2' | // font-size: 48px
    'subtitle1' | // font-size: 36px
    'subtitle2' | // font-size: 30px
    'content1' | // font-size: 24px
    'content2' | // font-size: 20px
    'caption1' | // font-size: 18px
    'caption2', // font-size: 16px
  color?:
    'primary' | // white
    'secondary' | // pink
    'tertiary' | // black
    'success' | // green
    'error', // red
  align?: 'left' | 'center' | 'right',
  weight?: 500 | 600 | 700,
  hasOpacity?: boolean,
  maxLines?: 1 | 2 | 3 | 4 | 5,
  className?: string,
  onClick?: () => void;
}

export const Text = memo(
  function Text(props: TextProps) {
    const {
      children,
      variant = 'caption2',
      color= 'primary',
      align= 'center',
      weight= 400,
      hasOpacity,
      maxLines,
      className,
      onClick,
    } = props;
    const opacityClass = hasOpacity ? 'opacity' : '';
    const maxLinesClass = maxLines ? `max-lines-${maxLines}` : '';

    return (
      <p
        className={`text variant-${variant} color-${color} align-${align} weight-${weight} ${opacityClass} ${maxLinesClass} ${className}`}
        onClick={onClick}
      >
        {children}
      </p>
    );
  }
);