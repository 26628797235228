import React from 'react';
import { Text } from '../../Text/Text';
import miniBitcoinIcon from '../../../assets/crypto/icons/bitcoin.png';
import miniLitecoinIcon from '../../../assets/crypto/icons/ltecoin.png';
import miniChainLinkIcon from '../../../assets/crypto/icons/chainlink.png';
import miniSiacoinIcon from '../../../assets/crypto/icons/siacoins.png';
import './MiniCryptoIcons.scss';

export const MiniCryptoIcons = () => {
  const cryptoList = [
    { icon: miniBitcoinIcon, label: 'Bitcoin' },
    { icon: miniLitecoinIcon, label: 'Litecoin' },
    { icon: miniChainLinkIcon, label: 'Chainlink' },
    { icon: miniSiacoinIcon, label: 'Siacoin' },
  ];
  return (
    <div className="mini-crypto-icons display-flex flex-wrap space-between align-items-center">
      {cryptoList.map((crypto) => (
        <div className="crypto-icon display-flex align-items-center gap-20" key={crypto.label}>
          <img src={crypto.icon} alt="bitcoin icon" className="display-block"/>
          <Text variant="caption2">
            {crypto.label}
          </Text>
        </div>
      ))}
    </div>
  );
};