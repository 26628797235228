export enum CryptoValue {
  BTC = 'Bitcoin',
  LTC = 'Litecoin',
  ETH = 'Ethereum',
}

export const CryptoList = {
  [CryptoValue.BTC]: { symbol: 'BTC', name: 'Bitcoin' },
  [CryptoValue.LTC]: { symbol: 'LTC', name: 'Litecoin' },
  [CryptoValue.ETH]: { symbol: 'ETH', name: 'Ethereum' },
};