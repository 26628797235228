import { memo } from 'react';
import { Button, Text } from '../../../components';
import { useTranslation } from 'react-i18next';
import failed from "../../../assets/svg-utils/error.svg";

type Props = {
  id: string;
  onTryAgain: () => void;
}

function MemoComponent({ id, onTryAgain }: Props) {
  const { t } = useTranslation();
  return (
    <div
      id={id}
      className="kyc-failed kyc-flow-content"
    >
      <img src={failed} className="display-block" alt="failed"/>

      <Text variant="title2" weight={700} color="secondary">
        {`${t('kyc.thirdStep.verificationFailed')}!`}
      </Text>

      <Text variant="content1" color="tertiary">
        {t('kyc.thirdStep.couldNotBeVerified')}
      </Text>

      <Button
        label={t("layout.button.tryAgain")}
        onClick={onTryAgain}
      />
    </div>
  );
}

export const KYCFailed = memo(MemoComponent);